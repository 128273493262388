import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import IndexLayout from '../../../layouts';
import FsLightbox from 'fslightbox-react';
// import { useLocation } from '@reach/router';
// import Breadcrumbs from '../../../components/breadcrumbs/breadcrumbs';

const PortfolioItem = ({ data }) => {
  const sources = data.portfolioJson.additional_images.map((item) => {
    return item.image.publicURL;
  });

  const [lightBoxControler, setLightBoxControler] = useState({
    toggler: false,
    slide: 1,
  });

  const openLightboxOnSlide = (number) => {
    setLightBoxControler({
      toggler: !lightBoxControler.toggler,
      slide: number,
    });
  };

  // const myPath = useLocation();

  return (
    <IndexLayout noScroll={true}>
      <div className='mx-auto flex min-h-screen max-w-screen-xl flex-col items-start justify-start px-4 pt-24'>
        {/* <Breadcrumbs path={myPath} work={data.portfolioJson.name} /> */}
        <h1 className='text-left text-3xl font-bold text-myOrange'>
          {data.portfolioJson.name}
        </h1>
        <div className='grid grid-cols-1 gap-4 py-4 sm:grid-cols-2 lg:grid-cols-3'>
          {data.portfolioJson.additional_images.map((item, index) => {
            return (
              <div
                key={index}
                className='cursor-zoom-in'
                onClick={() => openLightboxOnSlide(index + 1)}
                onKeyDown={() => openLightboxOnSlide(index + 1)}
                role='link'
                tabIndex={index}
              >
                <GatsbyImage
                  image={item.image.childImageSharp.gatsbyImageData}
                  className='h-80 w-full'
                  alt={item.image_alt}
                />
              </div>
            );
          })}
        </div>
        <Link
          to={`/realizacje/${data.portfolioJson.category.toLowerCase()}#${
            data.portfolioJson.id_work
          }`}
        >
          <button className='flex items-center gap-3 p-4'>
            <ArrowLeftIcon className='h-8 w-8 text-myOrange' />
            Wróć do realizacji
          </button>
        </Link>
        <FsLightbox
          toggler={lightBoxControler.toggler}
          sources={[...sources]}
          slide={lightBoxControler.slide}
        />
      </div>
    </IndexLayout>
  );
};

export const query = graphql`
  query ($id: String) {
    portfolioJson(id: { eq: $id }) {
      id
      id_work
      name
      category
      additional_images {
        image {
          id
          childImageSharp {
            gatsbyImageData(placeholder: DOMINANT_COLOR)
          }
          publicURL
        }
        image_alt
      }
    }
  }
`;

export default PortfolioItem;

export function Head() {
  return (
    <>
      <meta charSet='UTF-8' />
      <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <meta
        name='description'
        content='Meble biurowe, kuchenne, szafy, zabudowy. Wszystko na wymiar. Koszalin i okolice. Ponad 25 lat doświadczenia'
      />
      <meta name='robots' content='index,follow' />
      <title>Stromich - meble na wymiar.</title>
    </>
  );
}
